<template>
   <router-view></router-view>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.jump();
  },
  methods: {
    jump() {
      this.$router.push("/user-base-info");
    }
  },
 
};
</script>

<style>
</style>